import React from 'react'

interface Props {
  state: boolean
  bgColor?: string
  ignoreClick?: (...args: any) => void
  spinColor?: string
}

export default function Index({ state, children, bgColor, spinColor, ignoreClick = () => {}, ...restProps }: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div {...restProps} onClick={state ? ignoreClick : restProps.onClick} className={`overflow-hidden ${restProps.className}`}>
      {state ? (
        <div className="bg-[#66666680] w-full h-full absolute z-50 top-0 left-0 flex items-center justify-center" style={{ backgroundColor: bgColor }}>
          <svg className="animate-spin h-[60%] max-h-[100px] text-white" style={{ color: spinColor }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : null}
      {children}
    </div>
  )
}
