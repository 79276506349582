import { atom, createStore } from 'jotai'

export const myStore = createStore()

const user = localStorage.user ? JSON.parse(localStorage.user) : null

export const userState = atom(user)

export const vipEndState = atom(false)

export const serveModalState = atom(false)

export const memberState = atom<{
  memberFlag: boolean // 会员标识
  expireTime: string // 会员时间 2025.01.06
  remainDay: number // 过期时间
  expire: boolean // 是否过期
} | null>(null)

export const loginModalState = atom(false)

export const exchangeModalState = atom(false)
