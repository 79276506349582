import axios from 'axios'
import { load } from '@fingerprintjs/fingerprintjs'

// 重新排序
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

// 地址参数
export const getUrlParam = (name: string, url?: string) => {
  let u = url || window.location.href,
    reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'),
    r = u.substring(u.indexOf('?') + 1).match(reg)
  return r != null ? decodeURI(r[2]) : ''
}

// 下载文件
export const downloadFile = (blob: any, filename: string) => {
  const a = window.document.createElement('a')
  a.download = `${decodeURI(filename)}`
  a.href = window.URL.createObjectURL(blob)
  a.click()
  window.URL.revokeObjectURL(a.href)
}

// 防抖
export const debounce = (func: { apply: (arg0: undefined, arg1: any[]) => void }, time: number | undefined) => {
  let timer: string | number | NodeJS.Timeout | undefined
  return (...args: any[]) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, time)
  }
}

// 节流
export const throttle = (callback: { apply: (arg0: undefined, arg1: any[]) => void }, wait = 3000) => {
  let timer: any = null
  let startTime: number
  return (...args: any[]) => {
    const ctx = this
    const now = +new Date()
    if (startTime && now < startTime + wait) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        startTime = now
        callback.apply(ctx, args)
      }, wait)
    } else {
      startTime = now
      callback.apply(ctx, args)
    }
  }
}

// 强行睡觉,默认800
export const sleep = (time: number = 800) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(true)
    }, time)
  )
}

// 生成uuid
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// dataURL to file
export const dataURLToFile = (dataURL: string, filename: string) => {
  const arr = dataURL.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// img url to base64
export const convertImgToBase64 = async (url: string): Promise<string> => {
  const response = await axios({ url, responseType: 'blob' })
  const blob = response.data
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

// get img width height
export const getImgInfo = async (url: string) => {
  const base64 = await convertImgToBase64(url)
  return new Promise((resolve) => {
    const img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
}

// get base64 width height
export const getBase64Info = async (base64: string): Promise<{ width: number; height: number; img: HTMLImageElement }> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({ width: img.width, height: img.height, img })
    }
  })
}

// base64 mp3 to file
export const base64ToFile = (base64: string, filename: string) => {
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// base64 img change size, Long side=512,Short side=auto
export const resizeBase64Img = async (base64: string, maxSide: number = 512): Promise<{ width: number; height: number; base64: string }> => {
  const info = await getBase64Info(base64)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Unable to get canvas context')
  }

  const ratio = info.width > info.height ? maxSide / info.width : maxSide / info.height

  canvas.width = info.width * ratio
  canvas.height = info.height * ratio

  ctx.drawImage(info.img, 0, 0, canvas.width, canvas.height)
  const base64Url = canvas.toDataURL()
  return { width: canvas.width, height: canvas.height, base64: base64Url }
}

// base64 img change size, Long side=512,Short side=auto
export const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const isiPad = navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  if (/tablet|ipad|playbook|silk|kindle/i.test(userAgent) || isiPad) {
    return 'tablet'
  } else if (/mobile|android|touch|webos|hpwos/i.test(userAgent)) {
    return 'mobile'
  } else {
    return 'desktop'
  }
}

export const deviceBrowser = () => {
  const ua = navigator.userAgent.toLocaleLowerCase()
  // Safari
  // chrome
  // firefox
  // opera
  // IE
  // Edge
  // QQBrowser
  // UC
  // WeChat
  // Android
  // iOS
  // unknown
  if (ua.indexOf('micromessenger') > -1) {
    return 'WeChat'
  }
  if (ua.indexOf('qqbrowser') > -1) {
    return 'QQBrowser'
  }
  if (ua.indexOf('ucbrowser') > -1) {
    return 'UC'
  }
  if (ua.indexOf('edge') > -1) {
    return 'Edge'
  }
  if (ua.indexOf('opr') > -1 || ua.indexOf('opera') > -1) {
    return 'opera'
  }
  if (ua.indexOf('chrome') > -1) {
    return 'chrome'
  }
  if (ua.indexOf('safari') > -1) {
    return 'Safari'
  }
  if (ua.indexOf('firefox') > -1) {
    return 'firefox'
  }
  if (ua.indexOf('trident') > -1 || ua.indexOf('msie') > -1) {
    return 'IE'
  }
  if (ua.indexOf('android') > -1) {
    return 'Android'
  }
  if (/iphone|ipad|ipod/.test(ua)) {
    return 'iOS'
  }
  return 'unknown'
}

if (!localStorage.uuid) {
  localStorage.uuid = getUUID()
}

export const headerInfo = {
  deviceId: localStorage.uuid || getUUID(),
  appVersion: import.meta.env.VITE_APP_TIME,
  deviceType: getDeviceType(),
  deviceBrowser: deviceBrowser()
}

export const borwserEnv = {
  isWechat: /micromessenger/.test(navigator.userAgent.toLowerCase()),
  isAndroid: /android/.test(navigator.userAgent.toLowerCase()),
  isIOS: /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())
}

export const getVisitorId = (async () => {
  if (!localStorage.visitorId) {
    try {
      const agentPromise = load()
      const agent = await agentPromise
      const result = await agent.get()
      const { visitorId } = result
      localStorage.visitorId = visitorId
    } catch (error) {
      localStorage.visitorId = getUUID()
    }
    console.info(`%cvisitorId: %c${localStorage.visitorId}`, 'color: blue', 'color: #ff009d')
  } else {
    console.info(`%cvisitorId: %c${localStorage.visitorId}`, 'color: blue', 'color: #ff009d')
  }
  return localStorage.visitorId
})()
