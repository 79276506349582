import { Plugin } from 'xgplayer'
import closeImg from '@/assets/images/course/close.png'
import aiImg from '@/assets/images/course/ai.png'
import maxImg from '@/assets/images/course/max.png'
import max2Img from '@/assets/images/course/max2.png'
import minImg from '@/assets/images/course/min.png'
import { IPluginOptions } from 'xgplayer/es/plugin/plugin'

const { POSITIONS } = Plugin

export default class CtrlPlugin extends Plugin {
  full: boolean = false
  mini: boolean = false
  close_box: HTMLElement | null = null
  close: HTMLElement | null = null
  ai: HTMLElement | null = null
  max: HTMLElement | null = null
  max2: HTMLElement | null = null
  min: HTMLElement | null = null
  min2_box: HTMLElement | null = null
  min2: HTMLElement | null = null
  closeMin: Function | null = null
  jumpAi: Function | null = null
  fullScreen: Function | null = null
  minFloat: Function | null = null
  // 插件的名称，将作为插件实例的唯一key值
  static get pluginName() {
    return 'ctrlPlugin'
  }

  static get defaultConfig() {
    return {
      // 挂载在controls的右侧，如果不指定则默认挂载在播放器根节点上
      position: POSITIONS.ROOT_TOP,
      // 关小窗
      closeMin: null,
      // 跳ai
      jumpAi: null,
      // 全屏
      fullScreen: null,
      // 小窗
      minFloat: null
    }
  }

  constructor(args: IPluginOptions | undefined) {
    super(args)
  }

  beforePlayerInit() {
    // TODO 播放器调用start初始化播放源之前的逻辑
  }

  afterPlayerInit() {
    // TODO 播放器调用start初始化播放源之后的逻辑
  }

  afterCreate() {
    this.closeMin = () => {
      this.config.closeMin && this.config.closeMin()
      this.minFloat && this.minFloat()
    }

    this.jumpAi = () => {
      this.config.jumpAi && this.config.jumpAi()
      this.fullScreen && this.fullScreen(false)
    }

    this.fullScreen = (state = !this.full) => {
      this.full = state
      console.log(this.full)
      this.config.fullScreen && this.config.fullScreen(this.full)
      this.max && (this.max.style.display = this.full ? 'none' : 'flex')
      this.max2 && (this.max2.style.display = !this.full ? 'none' : 'flex')
    }

    this.minFloat = () => {
      this.mini = !this.mini
      this.config.minFloat && this.config.minFloat(this.mini)
      if (this.mini) {
        this.fullScreen && this.fullScreen(false)
        this.close_box && (this.close_box.style.display = 'flex')
        this.close && (this.close.style.display = 'flex')
        this.ai && (this.ai.style.display = 'none')
        this.max && (this.max.style.display = 'none')
        this.max2 && (this.max2.style.display = 'none')
        this.min && (this.min.style.display = 'none')
        this.min2_box && (this.min2_box.style.display = 'flex')
        this.min2 && (this.min2.style.display = 'flex')
      } else {
        this.close_box && (this.close_box.style.display = 'none')
        this.close && (this.close.style.display = 'none')
        this.ai && (this.ai.style.display = 'flex')
        this.max && (this.max.style.display = this.full ? 'none' : 'flex')
        this.max2 && (this.max2.style.display = !this.full ? 'none' : 'flex')
        this.min && (this.min.style.display = 'flex')
        this.min2_box && (this.min2_box.style.display = 'none')
        this.min2 && (this.min2.style.display = 'none')
      }
    }

    this.close_box = this.find('.close_box')
    this.close = this.find('.close')
    this.ai = this.find('.ai')
    this.max = this.find('.max')
    this.max2 = this.find('.max2')
    this.min = this.find('.min')
    this.min2_box = this.find('.min2_box')
    this.min2 = this.find('.min2')
    this.close_box && (this.close_box.style.display = 'none')
    this.close && (this.close.style.display = 'none')
    this.max2 && (this.max2.style.display = 'none')
    this.min2_box && (this.min2_box.style.display = 'none')
    this.min2 && (this.min2.style.display = 'none')

    this.bind('.close', 'click', this.closeMin)
    this.bind('.ai', 'click', this.jumpAi)
    this.bind('.max', 'click', () => this.fullScreen!(true))
    this.bind('.max2', 'click', () => this.fullScreen!(false))
    this.bind('.min', 'click', this.minFloat)
    this.bind('.min2', 'click', this.minFloat)
  }

  destroy() {
    this.unbind('.close', 'click', this.closeMin)
    this.unbind('.ai', 'click', this.jumpAi)
    this.unbind('.max', 'click', () => this.fullScreen!(true))
    this.unbind('.max2', 'click', () => this.fullScreen!(false))
    this.unbind('.min', 'click', this.minFloat)
    this.unbind('.min2', 'click', this.minFloat)
    this.close_box = null
    this.close = null
    this.ai = null
    this.max = null
    this.max2 = null
    this.min = null
    this.min2_box = null
    this.min2 = null
  }

  render() {
    return `
    <strong class="no-cursor w-full h-full">
      <div class="w-full h-full flex justify-end items-center">
        <div class="close_box flex-1 flex justify-start items-center">
          <div class="close text-white w-[40px] h-[38px] flex_center anim_btn !mr-[20px]"><img class="w-[20px] h-[20px]" src="${closeImg}"/></div>
        </div>
        <div class="ai text-white w-[40px] h-[38px] flex_center anim_btn !mr-[20px]"><img class="w-[30px] h-[28px]" src="${aiImg}"/></div>
    
        <div class="min text-white w-[40px] h-[38px] flex_center anim_btn !mr-[20px]"><img class="w-[30px] h-[28px]" src="${minImg}"/></div>
        <div class="min2_box flex-1 flex justify-end items-center">
          <div class="min2 text-white w-[40px] h-[38px] flex_center anim_btn"><img class="w-[30px] h-[28px]" src="${minImg}"/></div>
        </div>
      </div>
    </strong>`
  }
}

// <div class="max text-white w-[40px] h-[38px] flex_center anim_btn !mr-[20px]"><img class="w-[30px] h-[28px]" src="${maxImg}"/></div>
// <div class="max2 text-white w-[40px] h-[38px] flex_center anim_btn !mr-[20px]"><img class="w-[30px] h-[28px]" src="${max2Img}"/></div>
