import { useLocation, useNavigate } from 'react-router-dom'
import boardImg from '@/assets/images/nav/board.png'
import courseImg from '@/assets/images/nav/course.png'
import course1Img from '@/assets/images/nav/course1.png'
import homeImg from '@/assets/images/nav/home.png'
import home1Img from '@/assets/images/nav/home1.png'
import myImg from '@/assets/images/nav/my.png'
import my1Img from '@/assets/images/nav/my1.png'

const showNav = ['/course', '/my', '/']

export default function Index() {
  const location = useLocation()
  const navigate = useNavigate()
  // console.log(location)
  return showNav.includes(location.pathname) ? (
    <div className="w-screen h-[56px] fixed z-50 bottom-0 flex">
      <div className="w-full flex justify-around items-center bg-white text-[10px]">
        <div className="flex_center flex-col w-[70px]" onClick={() => navigate('/')}>
          <img className={`${location.pathname === '/' ? 'w-[70px] h-[70px]' : 'mb-[5px] w-[21px] h-[19px]'}`} src={location.pathname === '/' ? home1Img : homeImg} alt="" />
          <div className={`z-20 ${location.pathname === '/' ? 'text-[#5D63EA] translate-y-[-20px]' : 'text-[#636880 ]'}`}>首页</div>
        </div>
        <div className="flex_center flex-col w-[70px]" onClick={() => navigate('/course')}>
          <img className={`${location.pathname === '/course' ? 'w-[70px] h-[70px]' : 'mb-[5px] w-[19px] h-[19px]'}`} src={location.pathname === '/course' ? course1Img : courseImg} alt="" />
          <div className={`z-20 ${location.pathname === '/course' ? 'text-[#5D63EA] translate-y-[-20px]' : 'text-[#636880 ]'}`}>课程</div>
        </div>
        <div
          className="flex_center flex-col w-[70px]"
          onClick={() => {
            localStorage.fromRouter = location.pathname
            navigate('/board')
          }}
        >
          <img className="mb-[5px] w-[25px] h-[21px]" src={boardImg} alt="" />
          <div className="text-[#636880 ]">创作</div>
        </div>
        <div className="flex_center flex-col w-[70px]" onClick={() => navigate('/my')}>
          <img className={`${location.pathname === '/my' ? 'w-[70px] h-[70px]' : 'mb-[5px] w-[25px] h-[21px]'}`} src={location.pathname === '/my' ? my1Img : myImg} alt="" />
          <div className={`z-20 ${location.pathname === '/my' ? 'text-[#5D63EA] translate-y-[-20px]' : 'text-[#636880 ]'}`}>我的</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
