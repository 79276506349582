import { useState } from 'react'
import useAuthAction from '@/hooks/useAuthAction'
import { toast } from 'react-toastify'
import { orderActivate } from '@/api/course'
import { useAsyncFn, useToggle } from 'react-use'
import Modal from '@/components/Modal'
import { useAtom } from 'jotai'
import { exchangeModalState } from '@/store/global'
import ret2Img from '@/assets/images/vip/ret.png'
import activation_codeImg from '@/assets/images/vip/activation_code.png'
import Spin from '../Spin'
import h2Img from '@/assets/images/vip/h2.png'
import h1Img from '@/assets/images/vip/h1.png'
import ai2Img from '@/assets/images/vip/ai2.png'
import ai1Img from '@/assets/images/vip/ai1.png'
import successImg from '@/assets/images/vip/success.png'
import Div100vh from 'react-div-100vh'

export default function Index({ ret }: { ret?: () => void }) {
  const [exchangeModal, setExchangeModal] = useAtom(exchangeModalState)
  const [code, setCode] = useState('')
  const [success, setSuccess] = useState(false)

  const [orderActivateData, orderActivateFetch] = useAsyncFn<() => Promise<any>>(async () => {
    if (code === '') {
      toast('请输入兑换码')
      return ''
    }
    const res = await orderActivate({ code })
    setCode('')
    if (res.code !== 200) {
      toast(res.msg)
      return ''
    }
    // toast.success(res.msg)
    setSuccess(true)
    ret && ret()
    return null
  }, [code])

  return (
    <Modal mode={2} show={exchangeModal}>
      <Div100vh className="h-screen w-screen float-right relative flex justify-end">
        <div className="w-full h-full absolute z-0 left-0 top-0" onClick={() => setExchangeModal(false)}></div>
        <div className="h-full w-[440px] z-10  rounded-[20px_0px_0px_20px] bg-white mr-[-40px]">
          <div className="h-full w-[400px]">
            <div className="mx-[28px] mt-[32px]">
              <div className="h-[24px] w-full relative flex">
                <img className="anim_btn cursor-pointer w-[25px] h-[24px]" src={ret2Img} alt="" onClick={() => setExchangeModal(false)} />
                <div className="flex-1 flex_center font-medium text-[16px] text-[#272D53]">课程兑换</div>
                <div className="w-[25px] h-[24px]"></div>
              </div>
              {!success ? (
                <div className="flex_center flex-col mt-[39px]">
                  <input
                    className="indent-[20px] p-0 w-[344px] h-[52px] rounded-[26px] bg-[#EFF3F9] border-none font-normal text-[16px]"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                    placeholder="输入兑换码，例：UWDICP6"
                    name=""
                    id=""
                  />
                  <div className="mt-[16px] flex justify-between w-full relative rounded-[26px] overflow-hidden">
                    <Spin
                      state={orderActivateData.loading}
                      className="theme_btn anim_btn cursor-pointer flex-1 h-[52px] shadow-[0px_2_2px_0px_rgba(0,0,0,0.1)] rounded-[26px] font-medium text-[16px] text-white flex_center"
                      onClick={orderActivateFetch}
                    >
                      立即兑换
                    </Spin>
                  </div>
                  <div className="mt-[80px] w-full font-normal text-[16px] text-[#272D53] leading-[23px]">激活码位置示意图</div>
                  <div className="mt-[16px]">
                    <img className="w-[344px] h-[224px]" src={activation_codeImg} alt="" />
                  </div>
                </div>
              ) : (
                <div className="flex_center flex-col mt-[79px]">
                  <img src={successImg} className="w-[48px] h-[48px]" alt="" />
                  <div className="mt-[12px] font-medium text-[20px] text-[#272D53] leading-[28px]">兑换成功</div>
                  <div className="mt-[12px] font-normal text-[16px] text-[#636880] leading-[23px]">可到「课程页」查看</div>
                  <div className="mt-[80px] h-[23px] font-normal text-[16px] text-[#67391D] leading-[23px] flex_center">
                    <img className="w-[72px] h-[1px] mr-[12px]" src={h1Img} alt="" />
                    您获得以下权益
                    <img className="w-[72px] h-[1px] ml-[12px]" src={h2Img} alt="" />
                  </div>
                  <div className="mt-[24px] w-full h-[100px] flex justify-between items-center">
                    <img className="w-[164px] h-[100px]" src={ai1Img} alt="" />
                    <img className="w-[164px] h-[100px]" src={ai2Img} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Div100vh>
    </Modal>
  )
}
